/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-mobile-navigation {
  display: none;
}
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
.item-empty .hover {
  display: none !important;
}
/* LEVEL 1 */
div.sub1,
.sub1 > .item {
  width: 100%;
}
div.sub1 .menu {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  padding: 5px 10px;
}
div.sub1 .item.c1 .menu {
  background: #59287f;
}
div.sub1 .item.c2 .menu {
  background: #6e2876;
}
div.sub1 .item.c3 .menu {
  background: #8f3877;
}
div.sub1 .item.c4 .menu {
  background: #ab4063;
}
div.sub1 .item.c5 .menu {
  background: #be5054;
}
div.sub1 .item.c6 .menu {
  background: #c75b3c;
}
div.sub1 .item.c7 .menu {
  background: #ca6d1a;
}
/* LEVEL 2 */
div.sub2 {
  width: 100%;
  padding: 4px 0;
}
.sub2 > .item {
  width: 100%;
}
div.sub2 .menu {
  padding: 1px 10px;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  text-decoration: underline;
}
div.sub2 .item.c1 .menu {
  background: none;
  color: #59287f;
}
div.sub2 .item.c2 .menu {
  background: none;
  color: #6e2876;
}
div.sub2 .item.c3 .menu {
  background: none;
  color: #8f3877;
}
div.sub2 .item.c4 .menu {
  background: none;
  color: #ab4063;
}
div.sub2 .item.c5 .menu {
  background: none;
  color: #be5054;
}
div.sub2 .item.c6 .menu {
  background: none;
  color: #c75b3c;
}
div.sub2 .item.c7 .menu {
  background: none;
  color: #ca6d1a;
}
div.sub2 a.path {
  text-decoration: underline;
}
#services {
  float: right;
  width: 172px;
  margin-top: 2px;
}
#services .meta {
  border-right: 1px solid #ca6d1a;
  line-height: 1;
  float: left;
  font-size: 10px;
  padding: 0 8px;
  margin-bottom: 5px;
  color: #ca6d1a;
  text-decoration: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#services .meta.service_cust-imprint,
#services .meta.auth {
  padding-right: 0;
  border-right: none;
}
#services .meta:hover,
#services .meta:focus {
  color: #000;
}
/******* layout-large.less 2013-1-16 *******/
#head {
  width: 500px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 44px;
}
#home {
  margin-right: 6px;
}
#contactfix,
#navifix {
  position: fixed;
  left: 50%;
  top: 154px;
  width: 988px;
  margin-left: -494px;
  height: 0;
}
#navifix {
  width: 135px;
}
#header {
  position: fixed;
  top: 0;
  left: 50%;
  width: 988px;
  margin-left: -494px;
}
#fontsize {
  display: block;
  margin-right: 10px;
  margin-top: 90px;
}
#content {
  margin-top: 154px;
}
#navifix {
  display: block;
}
#navigation {
  margin-top: 50px;
  width: 135px;
}
#spenden {
  display: block;
  margin-top: 30px;
}
#spenden .service_spenden {
  display: block;
  padding: 7px 10px 5px;
  background-color: #59287f;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}
#header {
  margin-top: 0;
}
#contact {
  margin-top: 47px;
  width: 205px;
  padding: 0;
  background: none;
}
#maincontent {
  margin-left: 175px;
  width: 520px;
  padding-bottom: 50px;
  margin-right: 0;
}
#servicewidth {
  display: block;
}
.vcard {
  display: block;
}
.vcard.mobile {
  display: none;
}
.cb-album.cb-manual .head .ctrl .prev {
  left: 10px;
}
.cb-album.cb-manual .head .ctrl .next {
  right: 10px;
}
.area {
  width: 520px;
}
.area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 520px;
}
.area h2,
.area .foot {
  margin-right: 10px;
  margin-left: 10px;
}
.area .part,
.area > .grid table {
  margin-right: 10px;
  margin-left: 10px;
  width: 500px;
}
.area .tiny {
  width: 240px;
}
.area > .slim {
  width: 260px;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 240px;
}
.area > .slim .tiny {
  margin-right: 10;
  margin-left: 10;
  width: 110px;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 10px;
  margin-left: 10px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  margin-left: 0;
  margin-right: 0;
}
div.area div.seam,
div.area div.flat {
  margin-left: 10px;
  margin-right: 10px;
}
div.area div.seam.wide,
div.area div.flat.wide {
  width: 500px;
}
div.area div.seam.wide .part.tall,
div.area div.flat.wide .part.tall {
  width: 480px;
}
div.area div.seam.wide .part.tiny,
div.area div.flat.wide .part.tiny {
  width: 230px;
}
div.area div.seam.slim,
div.area div.flat.slim {
  width: 240px;
}
div.area div.seam.slim .part.tall,
div.area div.flat.slim .part.tall {
  width: 220px;
}
div.area div.seam.slim .part.tiny,
div.area div.flat.slim .part.tiny {
  width: 100px;
}
#maincontent div.flat:before {
  content: ' ';
  width: 19px;
  height: 38px;
  display: block;
  position: absolute;
  left: -19px;
  top: 26px;
}
.c1 #maincontent div.flat:before {
  background: url(/images/corner-c1.png) no-repeat 0 0;
}
.c2 #maincontent div.flat:before {
  background: url(/images/corner-c2.png) no-repeat 0 0;
}
.c3 #maincontent div.flat:before {
  background: url(/images/corner-c3.png) no-repeat 0 0;
}
.c4 #maincontent div.flat:before {
  background: url(/images/corner-c4.png) no-repeat 0 0;
}
.c5 #maincontent div.flat:before {
  background: url(/images/corner-c5.png) no-repeat 0 0;
}
.c6 #maincontent div.flat:before {
  background: url(/images/corner-c6.png) no-repeat 0 0;
}
.c7 #maincontent div.flat:before {
  background: url(/images/corner-c7.png) no-repeat 0 0;
}
div.unit div.head h2 {
  padding-top: 0;
}
div.main div.cb-album {
  width: 500px;
  margin-left: 10px;
  margin-right: 10px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */